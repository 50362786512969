import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const SidebarComponent = ({ toggle }: any) => {
  const navigate = useNavigate();

  // 링크이동
  const goToService = () => {
    navigate("/service");
    toggle();
  };
  const goToContact = () => {
    navigate("/contact");
    toggle();
  };

  return (
    <Style>
      <div className="slideBarWrap">
        <div className="sideBar">
          <button onClick={toggle}>
            <img
              src={`${process.env.PUBLIC_URL}/svg/ic_close.svg`}
              alt="사이드바 닫기 아이콘"
              className="closeButton"
            />
          </button>
          <div className="linkWrap">
            <button
              onClick={() => {
                navigate("/info");
                toggle();
              }}
            >
              <h2>회사소개</h2>
            </button>
            <button
              onClick={() => {
                navigate("/news");
                toggle();
              }}
            >
              <h2>뉴스</h2>
            </button>
            <button
              onClick={() => {
                window.open("https://behind.ivid.kr/", "_blank");
                toggle();
              }}
            >
              <h2>FOODTECH</h2>
            </button>
            <button
              onClick={() => {
                window.open("https://throo.co.kr/", "_blank");
                toggle();
              }}
            >
              <h2>MOBILITY</h2>
            </button>
            <button
              onClick={() => {
                window.open("https://knock-knock.ivid.kr/", "_blank");
                toggle();
              }}
            >
              <h2>TRAVEL TECH</h2>
            </button>
            <button
              onClick={() => {
                navigate("/contact");
                toggle();
              }}
            >
              <h2>CONTACT US</h2>
            </button>
          </div>
        </div>
      </div>
    </Style>
  );
};
export default SidebarComponent;

const Style = styled.div`
  .slideBarWrap {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    transition: transform 0.8s ease;
    z-index: 999999;
  }
  button {
    background: none;
    border: none;
    outline: none;
  }
  .sideBar {
    position: absolute;
    background: #000;
    width: 80%;
    height: 100vh;
    right: 0;
    padding: 16px;

    h2 {
      margin-top: 30px;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
    }
  }

  .linkWrap {
    margin-left: 16px;
    button {
      width: 100%;
      text-align: left;
    }
  }
`;
