import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const FooterComponent = () => {
  const navigate = useNavigate();

  return (
    <Style>
      <div className="footerWrap">
        <div className="linkWrap">
          <div className="linkContent">
            <div className="title">COMPANY</div>
            <button onClick={() => navigate("/info")}>회사소개</button>
            <button onClick={()=>window.open("https://map.naver.com/p/search/%EB%85%B8%ED%81%AC%EB%85%B8%ED%81%AC/place/1700816412?placePath=?entry=pll&from=nx&fromNxList=true&searchType=place&c=15.00,0,0,0,dh", "_blank")}>찾아오시는길</button>
          </div>
          <div className="linkContent">
            <div className="title">SOLUTION</div>
            <button onClick={()=>window.open("https://throo.co.kr/", "_blank")}>THROO</button>
            <button onClick={()=>window.open("https://behind.ivid.kr/", "_blank")}>BEHIND</button>
            <button onClick={()=>window.open("https://knock-knock.ivid.kr/", "_blank")}>KNOCKKNOCK</button>
          </div>
          {/* <div className="linkContent">
            <div className="title">PARTNERS</div>
            <button>고객사</button>
          </div> */}
          <div className="linkContent">
            <div className="title">COMMUNITY</div>
            <button onClick={() => navigate("/news")}>뉴스룸</button>
          </div>
          <div className="linkContent">
            <div className="title">CONTACT US</div>
            <button onClick={() => navigate("/contact")}>CONTACT US</button>
          </div>
        </div>
        <div className="companyInfoWrap">
          <p>(주)인비저블아이디어</p>
          <p>대표자: 윤언식 사업자등록번호: 159-86-01794</p>
          <p>대표번호: 1670-5324 이메일: partners@ivid.kr</p>
          <p>주소: 대구 북구 호암로 51, 메이커스페이스동 2층 (본사) </p>
          <p>서울 강남구 선릉로 622, 대영빌딩 2층 (연구소)</p>
          <p>ⓒ2025 INVISIBLEIDEAS Inc. All Rights Reserved.</p>
        </div>
      </div>
    </Style>
  );
};
export default FooterComponent;

const Style = styled.div`
  /* headerWrap */
  .footerWrap {
    width: 100%;
    padding: 110px 300px 80px 300px;
    background: #111;

    img {
      width: 190px;
    }
  }

  /* linkWrap */
  .linkWrap {
    display: flex;
    justify-content: space-between;

    .linkContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
    }
    button {
      font-size: 18px;
      font-weight: 400;
      border: none;
      background: none;
      color: #979797;
    }
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #979797;
  }

  /* companyInfoWrap */
  .companyInfoWrap {
    margin-top: 121px;

    p {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 500;
      color: #979797;
      line-height: 24px;
    }
  }
  @media (max-width: 1500px) {
    .footerWrap {
      padding: 86px;
    }
  }
  @media (max-width: 1100px) {
    .footerWrap {
      padding: 73px 20px;

      img {
        width: 59px;
      }
    }
    .companyInfoWrap {
      margin-top: 81px;

      p {
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  @media (max-width: 800px) {
    .linkWrap {
      flex-wrap: wrap;
      padding: 0 4vw;
      justify-content: flex-start;
      gap: 30px;
      .linkContent {
        gap: 15px;
      }
      .linkContent:nth-child(1) {
        margin-bottom: 50px;
      }
      button {
        font-size: 14px;
      }
    }
    .title {
      font-size: 14px;
    }
  }
`;
